import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../Layout';
import BlogPostHeader from '../../molecules/BlogPostHeader';
import BlogPostPreview from '../../../v1/molecules/blog-post-preview';
import Infographic from '../../atoms/Infographic/Infographic';
import useStoryblok from '../../../../lib/storyblok';
import Bloks from '../../../atoms/Bloks';
import Section from './../Section/section';
import Grid from '../../atoms/Grid/Grid';
import cn from 'classnames';
import { Location } from '../../../../types';
import RaisedFloatingCard from '../../molecules/RaisedFloatingCard';
import storyblokImageResizeUrl from '../../../../lib/storyblok-image-resize';
import SocialIcons from '../../atoms/SocialIcons';

type Props = {
  location: Location;
  pageContext: any;
  data: any;
};

const BlogTemplate: React.FC<Props> = ({ location, pageContext, data }) => {
  let story = pageContext.story;
  story = useStoryblok(story, location);

  const headandfooterData = data.headerAndFooter.content;
  let content = JSON.parse(headandfooterData);

  let infographic = story.content.infographic;
  let hasInfographic = infographic && infographic.length > 0;

  let blogs = data.blogs?.edges;

  const imageContent = story.content.header[0]?.image;
  const hideImage = story.content.header[0]?.hideImage;

  return (
    <Layout location={location} headerData={content.header} footerData={content.footer}>
      <Bloks bloks={story.content.SEO} />
      <Section curved hero className="pb-10 text-center" curveColor="tertiary">
        <BlogPostHeader content={story.content.header} />
      </Section>

      {!hideImage && imageContent && (
        <RaisedFloatingCard containerClasses="m-0 p-2 lg:py-10 lg:px-14" maximumShift={130}>
          <figure className="w-full">
            <img
              className="mx-auto w-auto max-h-[400px]"
              src={storyblokImageResizeUrl(imageContent.filename, 0, 400, true)}
              alt={imageContent.alt}
              height={400}
            />
          </figure>
        </RaisedFloatingCard>
      )}

      <Grid
        as="article"
        className={cn(`py-10 md:py-20 `, !hideImage ? 'divide-y md:divide-y-0 md:divide-x' : '')}
      >
        <p
          className={cn(
            hideImage
              ? `w-full flex flex-row items-center col-span-full mb-10
          justify-center space-x-5 -mt-[250px] z-10`
              : `w-full flex flex-row md:flex-col items-center col-span-full md:col-span-1 lg:col-start-3 mb-10 md:mb-0
            justify-center md:justify-start space-x-5 md:space-x-0 md:space-y-5`,
          )}
        >
          <a href="https://twitter.com/HomepplLtd" target="_blank" rel="noreferrer">
            <SocialIcons type="rounded" icon="twitter" width={36} height={36} />
          </a>
          <a href="https://www.facebook.com/Homeppl/" target="_blank" rel="noreferrer">
            <SocialIcons type="rounded" icon="facebook" width={36} height={36} />
          </a>
          <a href="https://www.instagram.com/homeppl/" target="_blank" rel="noreferrer">
            <SocialIcons type="rounded" icon="instagram" width={36} height={36} />
          </a>
          <a href="https://www.linkedin.com/company/homeppl/" target="_blank" rel="noreferrer">
            <SocialIcons type="rounded" icon="linkedin" width={36} height={36} />
          </a>
        </p>

        <div
          className={cn(
            'col-span-full md:col-span-6  lg:col-span-7 lg:col-end-11 md:pl-8 pt-10 md:pt-0',
            {
              'lg:!col-start-2 lg:col-end-11': hasInfographic,
            },
          )}
        >
          <Bloks bloks={story.content.body} className="max-w-full" />
        </div>

        {hasInfographic && <Infographic content={infographic} />}
      </Grid>

      <h2 className="font-serif text-2rem md:text-3xl mx-auto text-center">More blogs from us!</h2>

      <Grid as="ul" className="my-10 grid-cols-3">
        {blogs.map((blog) => {
          let blogID = blog.node.id;
          let blogContent = JSON.parse(blog.node.content);

          const previewContent = {
            headerText: blogContent.header[0]?.header,
            headerImage: blogContent.header[0]?.image.filename,
            imageAltText: blogContent.header[0]?.image.alt,
            url: `${blog.node.full_slug}/`,
            imageWidth: 326,
            imageHeight: 205,
          };

          return (
            <li key={blogID} className={`col-span-4`}>
              <BlogPostPreview {...previewContent} size="secondary" />
            </li>
          );
        })}
      </Grid>
    </Layout>
  );
};

export default BlogTemplate;

export const query = graphql`
  query blogPagesVersionTwo($currentBlog: [String], $label: String) {
    blogs: allStoryblokEntry(
      filter: {
        field_component: { eq: "blogpost" }
        id: { nin: $currentBlog }
        field_label_string: { eq: $label }
      }
      sort: { fields: first_published_at, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          name
          full_slug
          content
        }
      }
    }
    headerAndFooter: storyblokEntry(full_slug: { eq: "blog/" }) {
      content
    }
  }
`;
