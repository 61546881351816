import React from 'react';
import storyblokImageResizeUrl from '../../../../lib/storyblok-image-resize';

type Props = {
  content: any;
};

const Infographic: React.FC<Props> = ({ content }) => (
  <figure className="col-span-4 sm:col-span-8 lg:col-start-2 lg:col-end-12 mt-8">
    <img
      src={storyblokImageResizeUrl(content[0].image.filename, 1248, 0)}
      alt={content[0].image.alt}
    />
  </figure>
);

export default Infographic;
